<template>
  <view-item title="喷涂业务统计">
    <template #operation>
      <button-ajax v-if="store.accessRightsHash.BUSINESS_REPORT_EXPORT" :method="operation.export.click">导出报表</button-ajax>
      <button-ajax :method="operation.exportUseage.click">导出用量分析</button-ajax>
    </template>

    <en-card class="h-full" body-class="h-full">
      <en-tabs v-model="tabs.active" @tab-click="tabs.click">
        <en-tab-pane label="考核统计" name="check"></en-tab-pane>
        <en-tab-pane label="考核及盘点统计" name="checkByStock"> </en-tab-pane>
        <en-tab-pane label="工单详情" name="workorder"> </en-tab-pane>
      </en-tabs>

      <flex-box>
        <template #default="{ height }">
          <en-scrollbar :height="height">
            <form-query :model="query.data" :method="tabs.click" :show-reset="false">
              <en-form-item label="网点">
                <cascader-maintain
                  v-model="query.data.tenants"
                  :ajax="{
                    action: 'GET /enospray/summary/business/alltenants',
                    convert(data) {
                      return data.map((item) => {
                        item.children = item.children.map((inner: any) => {
                          inner.message = inner.shortName
                          inner.code = inner.id
                          return inner
                        })
                        return item
                      })
                    }
                  }"
                  :props="{ label: 'message', value: 'code', checkStrictly: true }"
                  class="w-full"
                ></cascader-maintain>
              </en-form-item>
              <en-form-item label="提交日期">
                <en-date-picker v-model:start="query.data.startDate" v-model:end="query.data.endDate" type="daterange"></en-date-picker>
              </en-form-item>
            </form-query>

            <div class="py-5 flex items-center gap-10">
              <div>
                <span>钣面数</span>
                <span>:</span>
                <span class="text-red-500">{{ formatNumber(form.summary.grossSurface) }}</span>
              </div>
              <div>
                <span>工单数</span>
                <span>:</span>
                <span class="text-red-500">{{ formatNumber(form.summary.orderCount) }}</span>
              </div>
              <div>
                <span>产值</span>
                <span>:</span>
                <span class="text-red-500">{{ formatNumber(form.summary.grossOutput) }}</span>
              </div>
              <div>
                <span>结算金额</span>
                <span>:</span>
                <span class="text-red-500">{{ formatMoney(form.summary.settlementAmount) }}</span>
              </div>
              <div v-if="tabs.active === 'checkByStock'">
                <span>考核及盘点成本金额</span>
                <span>:</span>
                <span class="text-red-500">{{ formatMoney(form.summary.checkCost) }}</span>
              </div>
              <div v-if="tabs.active === 'check'">
                <span>考核成本金额</span>
                <span>:</span>
                <span class="text-red-500">{{ formatMoney(form.summary.cost) }}</span>
              </div>
              <div v-if="tabs.active === 'check' || tabs.active === 'checkByStock'">
                <span>{{ tabs.active === 'checkByStock' ? '考核及盘点成本占比' : '考核成本占比' }}</span>
                <span>:</span>
                <span class="text-red-500">{{ formatPercent(form.summary.checkCostRate) }}</span>
              </div>
              <div>
                <span>实际成本</span>
                <span>:</span>
                <span class="text-red-500">{{ formatPercent(form.summary.realCostRate) }}</span>
              </div>
              <div>
                <span>扫码枪比例</span>
                <span>:</span>
                <span class="text-red-500">{{ formatPercent(form.summary.autoRate) }}</span>
              </div>
              <div>
                <span>质保激活率</span>
                <span>:</span>
                <span class="text-red-500">{{ formatPercent(form.summary.warrantyRate) }}</span>
              </div>
            </div>

            <div class="py-5 flex gap-2">
              <div class="w-1 bg-primary"></div>
              <div>数据汇总</div>
            </div>

            <en-table :data="form.groupPaintingSummary" :loading="form.loading">
              <en-table-column>
                <template #default="{ row }">
                  <en-checkbox
                    :model-value="
                      query.data.paintTypeCode === row.businessTypeCode &&
                      query.data.chargingStandardCode === row.chargingStandardCode &&
                      query.data.businessName === row.businessName
                    "
                    @change="form.checkbox.change(row)"
                  ></en-checkbox>
                </template>
              </en-table-column>
              <en-table-column label="类型" prop="businessName"></en-table-column>
              <en-table-column label="钣面数" prop="surfaceCount"></en-table-column>
              <en-table-column label="工单数" prop="orderCount"></en-table-column>
              <en-table-column label="产值" prop="surfaceAmount">
                <template #default="{ row }">{{ formatMoney(row.surfaceAmount) }}</template>
              </en-table-column>
              <en-table-column label="结算金额(35%)" prop="settlementAmount">
                <template #default="{ row }">{{ formatMoney(row.settlementAmount) }}</template>
              </en-table-column>
              <en-table-column label="考核成本" prop="cost">
                <template #default="{ row }">{{ formatMoney(row.cost) }}</template>
              </en-table-column>
              <en-table-column label="考核及盘点成本" prop="checkCost">
                <template #default="{ row }">{{ formatMoney(row.cost) }}</template>
              </en-table-column>
              <en-table-column label="运营成本占比" prop="operateCostRatio">
                <template #default="{ row }">{{ formatPercent(row.operateCostRatio) }}</template>
              </en-table-column>
            </en-table>

            <template v-if="tabs.active === 'check' || tabs.active === 'checkByStock'">
              <div class="py-5 flex gap-2">
                <div class="w-1 bg-primary"></div>
                <div>{{ tabs.active === 'check' ? '考核统计用量分析' : '考核及盘点统计用量分析' }}</div>
              </div>

              <en-table :data="form.groupMaterialSummary" :loading="form.loading" :span-method="form.material.spanMethod">
                <en-table-column label="材料类别" prop="goodsType.goodsTypeCategory.message"></en-table-column>
                <en-table-column label="材料名称" prop="goodsType.message">
                  <template #default="{ row }">
                    <en-button type="primary" link @click="form.goodsType.click(row)"> {{ row.goodsType?.message }}</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="实际总用量" prop="grossUsage"></en-table-column>
                <en-table-column label="实际单面用量" prop="actualSingleUsage"></en-table-column>
                <en-table-column label="参考用量" prop="standardDosage"></en-table-column>
                <en-table-column label="超出参考用量" prop="overUsage"></en-table-column>
              </en-table>

              <template v-for="item of form.groupGoodsType">
                <div class="py-5 flex gap-2">
                  <div class="w-1 bg-primary"></div>
                  <div>{{ item.goodsType?.goodsTypeCategory.message }}</div>
                </div>

                <en-table
                  :data="form.data.goodsUsages?.filter((goods) => goods.goodsType?.goodsTypeCategory.code === item.goodsType?.goodsTypeCategory.code)"
                >
                  <en-table-column label="编号" prop="serialNo"></en-table-column>
                  <en-table-column label="类型" prop="goodsType.goodsTypeCategory.message"></en-table-column>
                  <en-table-column label="名称" prop="name"></en-table-column>
                  <en-table-column label="仓库库存(罐)" prop="storageStock" sortable></en-table-column>
                  <en-table-column label="调漆房库存(g)" prop="workShopStock" sortable></en-table-column>
                  <en-table-column label="剩余使用天数" prop="daysRemaining" sortable></en-table-column>
                  <en-table-column :label="item.goodsType?.goodsTypeCategory === 'PAINT' ? '工单用量(g)' : '领料用量(g)'" sortable></en-table-column>
                  <en-table-column :label="item.goodsType?.goodsTypeCategory === 'PAINT' ? '工单成本' : '领料成本'" sortable></en-table-column>
                  <en-table-column label="盘点总用量(g)" prop="totalCheckCount" sortable></en-table-column>
                  <en-table-column label="盘点总金额" prop="checkCost" sortable> </en-table-column>
                  <en-table-column label="自动盘点用量(g)" prop="autoCheckCount" sortable></en-table-column>
                  <en-table-column label="自动盘点金额" prop="autoCheckCost" sortable></en-table-column>
                  <en-table-column label="手工盘点用量(g)" prop="checkCount" sortable></en-table-column>
                  <en-table-column label="手工盘点金额" prop="checkCost" sortable>
                    <template #default="{ row }">{{ formatMoney(row.checkCost) }}</template>
                  </en-table-column>
                  <en-table-column label="总用量(g)" prop="totalCount" sortable>
                    <template #default="{ row }">{{ formatNumber(row.totalCount) }}</template>
                  </en-table-column>
                  <en-table-column label="总用量(库存规格/罐)" prop="storageSpectotalCount" sortable>
                    <template #default="{ row }">{{ formatNumber(row.storageSpectotalCount) }}</template>
                  </en-table-column>
                  <en-table-column label="日均用量" prop="preCount" sortable>
                    <template #default="{ row }">{{ formatNumber(row.preCount) }}</template>
                  </en-table-column>
                  <en-table-column label="总成本(￥)" prop="totalCost" sortable>
                    <template #default="{ row }">{{ formatMoney(row.totalCost) }}</template>
                  </en-table-column>
                </en-table>
              </template>
            </template>

            <template v-if="tabs.active === 'workorder'">
              <div class="py-5 flex gap-2">
                <div class="w-1 bg-primary"></div>
                <div>工单列表</div>
              </div>

              <en-table :data="workorder.data" :method="workorder.get" :paging="workorder.paging" pagination :loading="workorder.loading">
                <en-table-column label="提交日期" prop="businessDatetime">
                  <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderDto'] }">{{ formatDate(row.businessDatetime) }}</template>
                </en-table-column>
                <en-table-column label="工单号" prop="serialNo"></en-table-column>
                <en-table-column label="网点" prop="tenant.name"></en-table-column>
                <en-table-column label="车牌号" prop="plateNo"></en-table-column>
                <en-table-column label="收费类型" prop="chargingStandard.message"></en-table-column>
                <en-table-column label="钣面" prop="grossSurface"></en-table-column>
                <en-table-column label="产值" prop="basicSummmary.wechatUser.name"></en-table-column>
                <en-table-column label="成本" prop="basicSummmary.sharingCount"></en-table-column>
                <en-table-column label="成本占比" prop="costRatio">
                  <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderDto'] }">{{ formatPercent(row.costRatio) }}</template>
                </en-table-column>
              </en-table>
            </template>
          </en-scrollbar>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-dialog v-model="category.visible" :title="category.title + '用量排行'" width="80%">
    <en-table :data="category.table.data" :loading="category.table.loading">
      <en-table-column label="排名" type="index" width="80"></en-table-column>
      <en-table-column label="网点名称" prop="tenant.name" width="200"></en-table-column>
      <en-table-column label="所属分公司" prop="tenant.parent.message" width="120"></en-table-column>
      <en-table-column label="单面用量" prop="actualSingleUsage" sortable width="150"></en-table-column>
      <en-table-column label="总用量" prop="grossUsage" sortable width="150">
        <template #default="{ row }">{{ formatNumber(row.grossUsage) }}</template>
      </en-table-column>
      <en-table-column label="单位" prop="goodsType.measureUnit"></en-table-column>
      <en-table-column label="业务员" prop="tenant.salesman.name"></en-table-column>
      <en-table-column label="技术督导" prop="tenant.supervisor.name"></en-table-column>
      <en-table-column label="区域经理" prop="tenant.manager.name"></en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="category.visible = false">关 闭</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { flatten, pick, uniqBy } from 'lodash-es'
import dayjs from 'dayjs'
import { calculator } from '@enocloud/utils'

import type { TableSpanMethodData } from '@enocloud/components'

let startDate = ''
let endDate = ''
let now = dayjs()

if (now.date() >= 26) {
  startDate = now.set('D', 26).format('YYYY-MM-DD')
  endDate = now
    .set('M', now.month() + 1)
    .set('D', 25)
    .format('YYYY-MM-DD')
} else {
  startDate = now
    .set('M', now.month() - 1)
    .set('D', 26)
    .format('YYYY-MM-DD')
  endDate = now.set('D', 25).format('YYYY-MM-DD')
}

export default factory({
  config: {
    children: {
      operation: {
        export: {
          async click() {
            return this.ajax('GET /enospray/summary/business/report', {
              payload: Object.assign(pick(this.query.data, 'startDate', 'endDate'), {
                parentCode: this.query.data.tenants[0] ?? '',
                tenantId: this.query.data.tenants[1] ?? ''
              })
            })
          }
        },
        exportUseage: {
          click() {
            const url = new URL('/enospray/summary/business/usagesummary', import.meta.env.VITE_ENOCLOUD_URL)
            url.searchParams.append('startDate', this.query.data.startDate)
            url.searchParams.append('endDate', this.query.data.endDate)
            url.searchParams.append('endDate', this.query.data.endDate)
            url.searchParams.append('parentCode', this.query.data.tenants[0] ?? '')
            url.searchParams.append('tenantId', this.query.data.tenants[1] ?? '')
            window.open(url)
          }
        }
      },
      tabs: {
        active: 'check',
        click() {
          if (this.query.data.tenants.length) {
            if (this.tabs.active.startsWith('check')) this.form.get()
            else this.workorder.get()
          }
        }
      },
      query: {
        data: { tenants: [], startDate, endDate, paintTypeCode: '', chargingStandardCode: '', businessName: '' }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enospray/summary/business',
            data: 'object',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = pick(this.query.data, 'startDate', 'endDate')
              params.payload.parentCode = this.query.data.tenants[0] ?? ''
              params.payload.tenantId = this.query.data.tenants[1] ?? ''
            }
          }
        },
        computed: {
          groupPaintingSummary() {
            return flatten(Object.values(this.form.data.groupPaintingSummary ?? {}))
          },
          groupMaterialSummary() {
            return flatten(this.form.data.goodsSummaries?.map((item) => item.summaries) ?? [])
          },
          groupGoodsType() {
            return uniqBy(this.form.data.goodsUsages ?? [], (item) => item.goodsType?.goodsTypeCategory.code)
          },
          summary() {
            const { autoCount = 0, grossSurface = 0, grossOutput = 0, warrantyRate } = this.form.data

            const orderCount = this.form.groupPaintingSummary
              .filter((item) => item.businessTypeCode === 'O')
              .reduce((orderCount, item) => calculator.add(orderCount, item.orderCount), 0)

            const checkCost = this.form.groupPaintingSummary.reduce((checkCost, item) => calculator.add(checkCost, item.checkCost), 0)

            return {
              grossSurface: grossSurface,
              orderCount,
              grossOutput: grossOutput,
              settlementAmount: this.form.groupPaintingSummary.reduce(
                (settlementAmount, item) => calculator.add(settlementAmount, item.settlementAmount),
                0
              ),
              cost: this.form.groupPaintingSummary
                .filter((item) => item.businessTypeCode !== 'C')
                .reduce((cost, item) => calculator.add(cost, item.cost), 0),
              checkCost,
              checkCostRate: grossOutput ? calculator.div(checkCost, grossOutput) : 0,
              realCostRate: grossOutput
                ? calculator.div(
                    this.form.groupPaintingSummary.reduce((realCost, item) => calculator.add(realCost, item.realCost), 0),
                    grossOutput
                  )
                : 0,
              autoRate: autoCount ? calculator.div(autoCount, orderCount) : 0,
              warrantyRate
            }
          },
          materialSpans() {
            const hash = new Map()
            return this.form.groupMaterialSummary.map((item) => {
              const res = { rowspan: 0, colspan: 1 }
              const exist = hash.get(item.goodsType?.goodsTypeCategory.code!)

              if (!exist) hash.set(item.goodsType?.goodsTypeCategory.code!, true)

              res.rowspan = exist
                ? 0
                : this.form.groupMaterialSummary?.filter((g) => g.goodsType?.goodsTypeCategory.code === item.goodsType?.goodsTypeCategory.code)
                    .length ?? 1
              return res
            })
          }
        },
        children: {
          checkbox: {
            change(row: any) {
              this.query.data.paintTypeCode = row.businessTypeCode
              this.query.data.chargingStandardCode = row.chargingStandardCode
              this.query.data.businessName = row.businessName
            }
          },
          goodsType: {
            click(row: any) {
              this.category.title = row.goodsType.message
              this.category.table.get({
                addition: {
                  ...pick(this.query.data, 'startDate', 'endDate'),
                  parentCode: this.query.data.tenants[0] ?? '',
                  tenantId: this.query.data.tenants[1] ?? '',
                  goodsType: row.goodsType.code,
                  withinCheckData: this.tabs.active === 'checkByStock'
                }
              })
              this.category.visible = true
            }
          },
          material: {
            spanMethod({ rowIndex, columnIndex }: TableSpanMethodData) {
              if (columnIndex === 0) {
                return this.form.materialSpans?.[rowIndex]
              }
            }
          }
        }
      },
      workorder: {
        ajax: {
          get: {
            action: 'GET /enospray/workorder/enoch',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = pick(this.query.data, 'startDate', 'endDate')
              params.payload.parentCode = this.query.data.tenants[0] ?? ''
              params.payload.tenantId = this.query.data.tenants[1] ?? ''
            }
          }
        }
      },
      category: {
        visible: false,
        title: '',
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enospray/summary/business/rankusage',
                data: 'array',
                loading: true
              }
            }
          }
        }
      }
    }
  }
})
</script>
